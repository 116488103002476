export default {
  "ext": "ext.",
  "country": "Maa",
  "phone": "Puhelinnumero",
  "AB": "Abhasia",
  "AC": "Ascension",
  "AD": "Andorra",
  "AE": "Arabiemiirikunnat",
  "AF": "Afganistan",
  "AG": "Antigua ja Barbuda",
  "AI": "Anguilla",
  "AL": "Albania",
  "AM": "Armenia",
  "AO": "Angola",
  "AQ": "Antarktis",
  "AR": "Argentiina",
  "AS": "Amerikan Samoa",
  "AT": "Itävalta",
  "AU": "Australia",
  "AW": "Aruba",
  "AX": "Ahvenanmaa",
  "AZ": "Azerbaidžan",
  "BA": "Bosnia ja Hertsegovina",
  "BB": "Barbados",
  "BD": "Bangladesh",
  "BE": "Belgia",
  "BF": "Burkina Faso",
  "BG": "Bulgaria",
  "BH": "Bahrain",
  "BI": "Burundi",
  "BJ": "Benin",
  "BL": "Saint-Barthélemy",
  "BM": "Bermuda",
  "BN": "Brunei",
  "BO": "Bolivia",
  "BQ": "Bonaire, Sint Eustatius ja Saba",
  "BR": "Brasilia",
  "BS": "Bahama",
  "BT": "Bhutan",
  "BV": "Bouvet’nsaari",
  "BW": "Botswana",
  "BY": "Valko-Venäjä",
  "BZ": "Belize",
  "CA": "Kanada",
  "CC": "Kookossaaret",
  "CD": "Kongon demokraattinen tasavalta",
  "CF": "Keski-Afrikan tasavalta",
  "CG": "Kongon tasavalta",
  "CH": "Sveitsi",
  "CI": "Norsunluurannikko",
  "CK": "Cookinsaaret",
  "CL": "Chile",
  "CM": "Kamerun",
  "CN": "Kiina",
  "CO": "Kolumbia",
  "CR": "Costa Rica",
  "CU": "Kuuba",
  "CV": "Kap Verde",
  "CW": "Curaçao",
  "CX": "Joulusaari",
  "CY": "Kypros",
  "CZ": "Tšekki",
  "DE": "Saksa",
  "DJ": "Djibouti",
  "DK": "Tanska",
  "DM": "Dominica",
  "DO": "Dominikaaninen tasavalta",
  "DZ": "Algeria",
  "EC": "Ecuador",
  "EE": "Viro",
  "EG": "Egypti",
  "EH": "Länsi-Sahara",
  "ER": "Eritrea",
  "ES": "Espanja",
  "ET": "Etiopia",
  "FI": "Suomi",
  "FJ": "Fidži",
  "FK": "Falklandinsaaret",
  "FM": "Mikronesian liittovaltio",
  "FO": "Färsaaret",
  "FR": "Ranska",
  "GA": "Gabon",
  "GB": "Yhdistynyt kuningaskunta",
  "GD": "Grenada",
  "GE": "Georgia",
  "GF": "Ranskan Guayana",
  "GG": "Guernsey",
  "GH": "Ghana",
  "GI": "Gibraltar",
  "GL": "Grönlanti",
  "GM": "Gambia",
  "GN": "Guinea",
  "GP": "Guadeloupe",
  "GQ": "Päiväntasaajan Guinea",
  "GR": "Kreikka",
  "GS": "Etelä-Georgia ja Eteläiset Sandwichsaaret",
  "GT": "Guatemala",
  "GU": "Guam",
  "GW": "Guinea-Bissau",
  "GY": "Guyana",
  "HK": "Hongkong",
  "HM": "Heard ja McDonaldinsaaret",
  "HN": "Honduras",
  "HR": "Kroatia",
  "HT": "Haiti",
  "HU": "Unkari",
  "ID": "Indonesia",
  "IE": "Irlanti",
  "IL": "Israel",
  "IM": "Mansaari",
  "IN": "Intia",
  "IO": "Brittiläinen Intian valtameren alue",
  "IQ": "Irak",
  "IR": "Iran",
  "IS": "Islanti",
  "IT": "Italia",
  "JE": "Jersey",
  "JM": "Jamaika",
  "JO": "Jordania",
  "JP": "Japani",
  "KE": "Kenia",
  "KG": "Kirgisia",
  "KH": "Kambodža",
  "KI": "Kiribati",
  "KM": "Komorit",
  "KN": "Saint Kitts ja Nevis",
  "KP": "Korean demokraattinen kansantasavalta",
  "KR": "Korean tasavalta",
  "KW": "Kuwait",
  "KY": "Caymansaaret",
  "KZ": "Kazakstan",
  "LA": "Laos",
  "LB": "Libanon",
  "LC": "Saint Lucia",
  "LI": "Liechtenstein",
  "LK": "Sri Lanka",
  "LR": "Liberia",
  "LS": "Lesotho",
  "LT": "Liettua",
  "LU": "Luxemburg",
  "LV": "Latvia",
  "LY": "Libya",
  "MA": "Marokko",
  "MC": "Monaco",
  "MD": "Moldova",
  "ME": "Montenegro",
  "MF": "Saint-Martin",
  "MG": "Madagaskar",
  "MH": "Marshallinsaaret",
  "MK": "Pohjois-Makedonia",
  "ML": "Mali",
  "MM": "Myanmar",
  "MN": "Mongolia",
  "MO": "Macao",
  "MP": "Pohjois-Mariaanit",
  "MQ": "Martinique",
  "MR": "Mauritania",
  "MS": "Montserrat",
  "MT": "Malta",
  "MU": "Mauritius",
  "MV": "Malediivit",
  "MW": "Malawi",
  "MX": "Meksiko",
  "MY": "Malesia",
  "MZ": "Mosambik",
  "NA": "Namibia",
  "NC": "Uusi-Kaledonia",
  "NE": "Niger",
  "NF": "Norfolkinsaari",
  "NG": "Nigeria",
  "NI": "Nicaragua",
  "NL": "Alankomaat",
  "NO": "Norja",
  "NP": "Nepal",
  "NR": "Nauru",
  "NU": "Niue",
  "NZ": "Uusi-Seelanti",
  "OM": "Oman",
  "OS": "Etelä-Ossetia",
  "PA": "Panama",
  "PE": "Peru",
  "PF": "Ranskan Polynesia",
  "PG": "Papua-Uusi-Guinea",
  "PH": "Filippiinit",
  "PK": "Pakistan",
  "PL": "Puola",
  "PM": "Saint-Pierre ja Miquelon",
  "PN": "Pitcairn",
  "PR": "Puerto Rico",
  "PS": "Palestiina",
  "PT": "Portugali",
  "PW": "Palau",
  "PY": "Paraguay",
  "QA": "Qatar",
  "RE": "Réunion",
  "RO": "Romania",
  "RS": "Serbia",
  "RU": "Venäjä",
  "RW": "Ruanda",
  "SA": "Saudi-Arabia",
  "SB": "Salomonsaaret",
  "SC": "Seychellit",
  "SD": "Sudan",
  "SE": "Ruotsi",
  "SG": "Singapore",
  "SH": "Saint Helena",
  "SI": "Slovenia",
  "SJ": "Svalbard ja Jan Mayen",
  "SK": "Slovakia",
  "SL": "Sierra Leone",
  "SM": "San Marino",
  "SN": "Senegal",
  "SO": "Somalia",
  "SR": "Suriname",
  "SS": "Etelä-Sudan",
  "ST": "São Tomé ja Príncipe",
  "SV": "El Salvador",
  "SX": "Sint Maarten",
  "SY": "Syyria",
  "SZ": "Swazimaa",
  "TA": "Tristan da Cunha",
  "TC": "Turks- ja Caicossaaret",
  "TD": "Tšad",
  "TF": "Ranskan eteläiset alueet",
  "TG": "Togo",
  "TH": "Thaimaa",
  "TJ": "Tadžikistan",
  "TK": "Tokelau",
  "TL": "Itä-Timor",
  "TM": "Turkmenistan",
  "TN": "Tunisia",
  "TO": "Tonga",
  "TR": "Turkki",
  "TT": "Trinidad ja Tobago",
  "TV": "Tuvalu",
  "TW": "Taiwan",
  "TZ": "Tansania",
  "UA": "Ukraina",
  "UG": "Uganda",
  "UM": "Yhdysvaltain pienet erillissaaret",
  "US": "Yhdysvallat",
  "UY": "Uruguay",
  "UZ": "Uzbekistan",
  "VA": "Vatikaanivaltio",
  "VC": "Saint Vincent ja Grenadiinit",
  "VE": "Venezuela",
  "VG": "Brittiläiset Neitsytsaaret",
  "VI": "Yhdysvaltain Neitsytsaaret",
  "VN": "Vietnam",
  "VU": "Vanuatu",
  "WF": "Wallis ja Futunasaaret",
  "WS": "Samoa",
  "XK": "Kosovo",
  "YE": "Jemen",
  "YT": "Mayotte",
  "ZA": "Etelä-Afrikka",
  "ZM": "Sambia",
  "ZW": "Zimbabwe",
  "ZZ": "Kansainvälinen"
}