export default {
  "ext": "дод.",
  "country": "Країна",
  "phone": "Телефон",
  "AB": "Абхазія",
  "AC": "Острів Вознесіння",
  "AD": "Андорра",
  "AE": "Об'єднані Арабські Емірати",
  "AF": "Афганістан",
  "AG": "Антигуа і Барбуда",
  "AI": "Ангілья",
  "AL": "Албанія",
  "AM": "Вірменія",
  "AO": "Ангола",
  "AQ": "Антарктида",
  "AR": "Аргентина",
  "AS": "Американське Самоа",
  "AT": "Австрія",
  "AU": "Австралія",
  "AW": "Аруба",
  "AX": "Аландські острови",
  "AZ": "Азербайджан",
  "BA": "Боснія і Герцеговина",
  "BB": "Барбадос",
  "BD": "Бангладеш",
  "BE": "Бельгія",
  "BF": "Буркіна-Фасо",
  "BG": "Болгарія",
  "BH": "Бахрейн",
  "BI": "Бурунді",
  "BJ": "Бенін",
  "BL": "Сен-Бартельмі",
  "BM": "Бермудські Острови",
  "BN": "Бруней",
  "BO": "Боливия",
  "BQ": "Карибські Нідерланди",
  "BR": "Бразилія",
  "BS": "Багамські Острови",
  "BT": "Бутан",
  "BV": "Острів Буве",
  "BW": "Ботсвана",
  "BY": "Білорусь",
  "BZ": "Беліз",
  "CA": "Канада",
  "CC": "Кокосові Острови",
  "CD": "ДР Конго",
  "CF": "Центральноафриканська Республіка",
  "CG": "Республіка Конго",
  "CH": "Швейцарія",
  "CI": "Кот-д'Івуар",
  "CK": "Острови Кука",
  "CL": "Чилі",
  "CM": "Камерун",
  "CN": "КНР",
  "CO": "Колумбія",
  "CR": "Коста-Рика",
  "CU": "Куба",
  "CV": "Кабо-Верде",
  "CW": "Кюрасао",
  "CX": "Остров Різдва",
  "CY": "Кіпр",
  "CZ": "Чехія",
  "DE": "Німеччина",
  "DJ": "Джибуті",
  "DK": "Данія",
  "DM": "Домініка",
  "DO": "Домініканська Республіка",
  "DZ": "Алжир",
  "EC": "Еквадор",
  "EE": "Естонія",
  "EG": "Єгипет",
  "EH": "Західна Сахара",
  "ER": "Еритрея",
  "ES": "Іспания",
  "ET": "Ефіопія",
  "FI": "Фінляндія",
  "FJ": "Фіджі",
  "FK": "Фолклендські Острови",
  "FM": "Федеративні Штати Мікронезії",
  "FO": "Фарерські Острови",
  "FR": "Франція",
  "GA": "Габон",
  "GB": "Велика Британія",
  "GD": "Гренада",
  "GE": "Грузія",
  "GF": "Французька Гвіана",
  "GG": "Гернсі",
  "GH": "Гана",
  "GI": "Гібралтар",
  "GL": "Гренландія",
  "GM": "Гамбія",
  "GN": "Гвінея",
  "GP": "Гваделупа",
  "GQ": "Екваторіальна Гвінея",
  "GR": "Греція",
  "GS": "Південна Джорджія та Південні Сандвічеві Острови",
  "GT": "Гватемала",
  "GU": "Гуам",
  "GW": "Гвінея-Бісау",
  "GY": "Гаяна",
  "HK": "Гонконг",
  "HM": "Острів Херд і Острови Макдональд",
  "HN": "Гондурас",
  "HR": "Хорватія",
  "HT": "Гаїті",
  "HU": "Угорщина",
  "ID": "Індонезія",
  "IE": "Ірландія",
  "IL": "Ізраїль",
  "IM": "Острів Мен",
  "IN": "Індія",
  "IO": "Британська Територія в Індійському Океані",
  "IQ": "Ірак",
  "IR": "Іран",
  "IS": "Ісландія",
  "IT": "Італія",
  "JE": "Джерсі",
  "JM": "Ямайка",
  "JO": "Йорданія",
  "JP": "Японія",
  "KE": "Кенія",
  "KG": "Киргизстан",
  "KH": "Камбоджа",
  "KI": "Кірибаті",
  "KM": "Коморські Острови",
  "KN": "Сент-Кіттс і Невіс",
  "KP": "Північна Корея",
  "KR": "Південна Корея",
  "KW": "Кувейт",
  "KY": "Кайманові Острови",
  "KZ": "Казахстан",
  "LA": "Лаос",
  "LB": "Ліван",
  "LC": "Сент-Люсія",
  "LI": "Ліхтенштейн",
  "LK": "Шрі-Ланка",
  "LR": "Ліберія",
  "LS": "Лесото",
  "LT": "Литва",
  "LU": "Люксембург",
  "LV": "Латвія",
  "LY": "Лівія",
  "MA": "Марокко",
  "MC": "Монако",
  "MD": "Молдова",
  "ME": "Чорногорія",
  "MF": "Сен-Мартен",
  "MG": "Мадагаскар",
  "MH": "Маршаллові Острови",
  "MK": "Північна Македонія",
  "ML": "Малі",
  "MM": "М'янма",
  "MN": "Монголія",
  "MO": "Макао",
  "MP": "Північні Маріанські Острови",
  "MQ": "Мартиніка",
  "MR": "Мавританія",
  "MS": "Монтсеррат",
  "MT": "Мальта",
  "MU": "Маврикій",
  "MV": "Мальдіви",
  "MW": "Малаві",
  "MX": "Мексика",
  "MY": "Малайзія",
  "MZ": "Мозамбік",
  "NA": "Намібія",
  "NC": "Нова Каледонія",
  "NE": "Нігер",
  "NF": "Острів Норфолк",
  "NG": "Нігерія",
  "NI": "Нікарагуа",
  "NL": "Нідерланди",
  "NO": "Норвегія",
  "NP": "Непал",
  "NR": "Науру",
  "NU": "Ніуу",
  "NZ": "Нова Зеландія",
  "OM": "Оман",
  "OS": "Південна Осетія",
  "PA": "Панама",
  "PE": "Перу",
  "PF": "Французька Полінезія",
  "PG": "Папуа Нова Гвінея",
  "PH": "Філіппіни",
  "PK": "Пакистан",
  "PL": "Польща",
  "PM": "Сен-П'єр і Мікелон",
  "PN": "Піткерн",
  "PR": "Пуерто-Рико",
  "PS": "Палестина",
  "PT": "Португалія",
  "PW": "Палау",
  "PY": "Парагвай",
  "QA": "Катар",
  "RE": "Реюньйон",
  "RO": "Румунія",
  "RS": "Сербія",
  "RU": "Росія",
  "RW": "Руанда",
  "SA": "Саудівська Аравія",
  "SB": "Соломонові Острови",
  "SC": "Сейшельські Острови",
  "SD": "Судан",
  "SE": "Швеція",
  "SG": "Сінгапур",
  "SH": "Острови Святої Єлени, Вознесіння і Тристан-да-Кунья",
  "SI": "Словенія",
  "SJ": "Свальбард і Ян-Маєн",
  "SK": "Словаччина",
  "SL": "Сьєрра-Леоне",
  "SM": "Сан-Марино",
  "SN": "Сенегал",
  "SO": "Сомалі",
  "SR": "Суринам",
  "SS": "Південний Судан",
  "ST": "Сан-Томе і Принсіпі",
  "SV": "Сальвадор",
  "SX": "Сінт-Мартен",
  "SY": "Сирія",
  "SZ": "Есватіні",
  "TA": "Тристан-да-Кунья",
  "TC": "Острови Теркс і Кайкос",
  "TD": "Чад",
  "TF": "Французькі Південні і Антарктичні Території",
  "TG": "Того",
  "TH": "Таїланд",
  "TJ": "Таджикистан",
  "TK": "Токелау",
  "TL": "Східний Тимор",
  "TM": "Туркменістан",
  "TN": "Туніс",
  "TO": "Тонга",
  "TR": "Туреччина",
  "TT": "Тринідад і Тобаго",
  "TV": "Тувалу",
  "TW": "Тайвань",
  "TZ": "Танзанія",
  "UA": "Україна",
  "UG": "Уганда",
  "UM": "Зовнішні малі острови США",
  "US": "США",
  "UY": "Уругвай",
  "UZ": "Узбекистан",
  "VA": "Ватикан",
  "VC": "Сент-Вінсент і Гренадини",
  "VE": "Венесуела",
  "VG": "Британські Віргінські Острови",
  "VI": "Американські Віргінські Острови",
  "VN": "В'єтнам",
  "VU": "Вануату",
  "WF": "Волліс і Футуна",
  "WS": "Самоа",
  "XK": "Косово",
  "YE": "Ємен",
  "YT": "Майотта",
  "ZA": "ПАР",
  "ZM": "Замбія",
  "ZW": "Зімбабве",
  "ZZ": "Міжнародний"
}